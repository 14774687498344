module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"Covesting is a software development company.","short_name":"Covesting: Trading Software Development","description":"Covesting is a software development company. We build cutting-edge solutions and partner with ambitious companies around the world.","start_url":"https://covesting.io","background_color":"#FFFFFF","theme_color":"#0E6FBD","lang":"en","display":"browser","shortcuts":[{"name":"Open web platform PrimeXBT ","short_name":"Open web","description":"Covesting in PrimeXBT web platform","url":"https://primexbt.com/id/sign-up?init-product=covesting","icons":[{"src":"/images/web-platform-icon-192x192.png","sizes":"192x192"}]},{"name":"Open Apple application","short_name":"Apple app","description":"Covesting in PrimeXBT app","url":"https://apps.apple.com/us/app/primexbt-crypto-trading/id1522267195","icons":[{"src":"/images/app-icon.png","sizes":"480x480"}]},{"name":"Open Android application","short_name":"Android app","description":"Covesting in PrimeXBT app","url":"https://play.google.com/store/apps/details?id=com.primexbt.trade","icons":[{"src":"/images/app-icon.png","sizes":"480x480"}]}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a600271780f3f7657e92d280c154e37f"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Open Sans","file":"https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
